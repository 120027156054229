/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Field, ErrorMessage } from 'formik'
import { environment } from '../../../../../environments/environment'
import { useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import { Spinner } from 'react-bootstrap'
import { createAccountSchemas, ICreateAccount, inits } from '../CreateAccountWizardHelper'
interface IHorario {
    InfoAdicionalGrupoId: number,
    horarioId: string,
    Slabel: string,
    HoraIni: string,
    HoraFin: string,
    NroDia: number,
    Beliminado: boolean
}
interface ICupon {
    valorCursoId: string,
    Activo: string,
    Codigo: string,
    Valor: number,
    Cantidad: number,
    Beliminado: boolean
}
const Step1: FC = () => {
    const [initValues] = useState<ICreateAccount>(inits)
    const [modalidad, setmodalidad] = useState([])
    const [fechaInicioMatricula, setfechaInicioMatricula] = useState("")
    const [fechaFinMatricula, setfechaFinMatricula] = useState("")
    const [fechaInicioPagoCompleto, setfechaInicioPagoCompleto] = useState("")
    const [fechaInicioPagoExtempo, setfechaInicioPagoExtempo] = useState("")
    const [datosCargados, setDatosCargados] = useState({
        codigo: "",
        nombre: "",
        cupo: 0,
        programa: "",
        modalidad: 0,
        inicioCurso: "",
        finCurso: "",
        Descripcion: "",
        infoAdicionalGrupoId: 0
    })
    const Semana = [
        { value: 0, label: 'Domingo' },
        { value: 1, label: 'Lunes' },
        { value: 2, label: 'Martes' },
        { value: 3, label: 'Miercoles' },
        { value: 4, label: 'Jueves' },
        { value: 5, label: 'Viernes' },
        { value: 6, label: 'Sabado' },
    ]
    const [cargado, setcargado] = useState(false)
    const [cupones, setCupones] = useState<ICupon[]>([])
    const [pagoParcial, setPagoParcial] = useState(false)
    const [idProntopago, setidProntopago] = useState(0);
    const [idPagoCompleto, setidPagoCompleto] = useState(0);
    const [idPagoExtempo, setidPagoExtempo] = useState(0);
    const [idPagoParcial, setidPagoParcial] = useState(0);
    const [idCertificado, setidCertificado] = useState(0);
    const [valorc, setvalorc] = useState(0);
    const [tieneHorario, settieneHorario] = useState(false)

    const [horario, setHorario] = useState<IHorario[]>([])
    const baseurl = environment.acamoodURL
    let location = useLocation();
    const path = location.pathname.split('/')[location.pathname.split("/").length - 1]
    const url = baseurl + environment.EducativeOffertURL + environment.OffertByCod + path
    const [publicado, setpublicado] = useState(false)
    const [datosValor, setDatosValor] = useState({
        fechainicioPromtoPago: '',
        fechaFinProntoPago: '',
        valorProntoPago: 0,
        fechainicioPagoCompleto: '',
        fechaFinPagoCompleto: '',
        valorPagoCompleto: 0,
        fechainicioPagoExtemporaneo: '',
        fechaFinPagoExtemporaneo: '',
        valorPagoExtemporaneo: 0,
        BpagoParcial: false,
        valorPagoParcial: 0
    })
    const navigate = useNavigate();
    let today = new Date().toLocaleDateString('en-ca')
    function loadModalidad(codigo: { value: string }) {
        return +codigo.value === +datosCargados.modalidad;
    }

    useEffect(() => {

        const CargarDatos = async () => {
            const respuesta = await fetch(url)
            const resultado = await respuesta.json()
            initValues.nombreCurso=resultado.data.nombreGrupo
            initValues.FechaInicio=new Date(resultado.data.fechaInicial).toLocaleDateString('en-ca')
            initValues.FechaFin=new Date(resultado.data.fechaFinal).toLocaleDateString('en-ca')
            initValues.codigoGrupo = resultado.data.codigoGrupo
            initValues.infoAdicionalGrupoId = resultado.data.infoAdicionalGrupoId
            let ifechainicioPromtoPago = ""
            let ifechaFinProntoPago = ""
            let ivalorProntoPago = 0
            let ifechainicioPagoCompleto = ""
            let ifechaFinPagoCompleto = ""
            let ivalorPagoCompleto = 0
            let ifechainicioPagoExtemporaneo = ""
            let ifechaFinPagoExtemporaneo = ""
            let ivalorPagoExtemporaneo = 0
            let Vcertificado = 0
            let Vpagoparcial = 0
            let datosh: IHorario[] = []
            if (resultado.data.tipoPrograma != "Curso") {
                initValues.tipo = true
            }
            if (resultado.data.tieneHorario) {
                resultado.data.Horario.map(
                    (valor: any) => {
                        datosh = [...datosh, {
                            InfoAdicionalGrupoId: resultado.data.infoAdicionalGrupoId,
                            horarioId: valor.horarioId,
                            Slabel: "",
                            HoraIni: valor.HoraIni,
                            HoraFin: valor.HoraFin,
                            NroDia: valor.NroDia,
                            Beliminado: false
                        }]
                    })
                settieneHorario(true)
                setHorario(datosh)
            }
            let datosc: ICupon[] = []
            if (resultado.data.esPago) {
                let idPpago = 0
                let idPCompleto = 0
                let idPextempo = 0
                resultado.data.valor.map(
                    (valor: any) => {
                        if (valor.tipoValorId == 1) {
                            setidProntopago(+valor.valorCursoId)
                            ifechainicioPromtoPago = valor.fechaInicialVigencia
                            ifechaFinProntoPago = valor.fechaFinalVigencia
                            ivalorProntoPago = valor.valorCurso
                            idPpago = +valor.valorCursoId
                        }
                        if (valor.tipoValorId == 2) {
                            setidPagoCompleto(+valor.valorCursoId)
                            setfechaInicioPagoCompleto(new Date(valor.fechaInicialVigencia).toLocaleDateString('en-ca'))
                            ifechainicioPagoCompleto = valor.fechaInicialVigencia
                            ifechaFinPagoCompleto = valor.fechaFinalVigencia
                            ivalorPagoCompleto = valor.valorCurso
                            idPCompleto = +valor.valorCursoId
                        }
                        if (valor.tipoValorId == 3) {
                            setfechaInicioPagoExtempo(new Date(valor.fechaInicialVigencia).toLocaleDateString('en-ca'))
                            setidPagoExtempo(+valor.valorCursoId)
                            ifechainicioPagoExtemporaneo = valor.fechaInicialVigencia
                            ifechaFinPagoExtemporaneo = valor.fechaFinalVigencia
                            ivalorPagoExtemporaneo = valor.valorCurso
                            idPextempo = +valor.valorCursoId
                            if (today > new Date(valor.fechaFinalVigencia).toLocaleDateString('en-ca')) {
                                navigate("/courses")
                            }
                        }
                        localStorage.removeItem('Pparcial')
                        if (valor.tipoValorId == 6) {
                            setidPagoParcial(valor.valorCursoId)
                            Vpagoparcial = valor.valorParcial
                            initValues.TBPagoParcial = true
                            initValues.valorMinParcial = valor.valorParcial
                            
                        }
                        setDatosValor({
                            fechainicioPromtoPago: new Date(ifechainicioPromtoPago).toLocaleDateString('en-ca'),
                            fechaFinProntoPago: new Date(ifechaFinProntoPago).toLocaleDateString('en-ca'),
                            valorProntoPago: ivalorProntoPago,
                            fechainicioPagoCompleto: new Date(ifechainicioPagoCompleto).toLocaleDateString('en-ca'),
                            fechaFinPagoCompleto: new Date(ifechaFinPagoCompleto).toLocaleDateString('en-ca'),
                            valorPagoCompleto: ivalorPagoCompleto,
                            fechainicioPagoExtemporaneo: new Date(ifechainicioPagoExtemporaneo).toLocaleDateString('en-ca'),
                            fechaFinPagoExtemporaneo: new Date(ifechaFinPagoExtemporaneo).toLocaleDateString('en-ca'),
                            valorPagoExtemporaneo: ivalorPagoExtemporaneo,
                            BpagoParcial: pagoParcial,
                            valorPagoParcial: Vpagoparcial,
                        })
                        let valor1 = 0;
                        let valorid = 0;
                        if (today <= ifechaFinPagoExtemporaneo) {
                            valor1 = ivalorPagoExtemporaneo
                            valorid = idPextempo
                        }
                        if (today <= ifechaFinPagoCompleto) {
                            valor1 = ivalorPagoCompleto
                            valorid = idPCompleto
                        }
                        if (today <= ifechaFinProntoPago) {
                            valor1 = ivalorProntoPago
                            valorid = idPpago
                        }
                        initValues.valorPagar = valor1
                        initValues.valorcursoid = valorid
                    }

                )
                setCupones(datosc);
            } else {
                resultado.data.valor.map(
                    (value: any) => {
                        if (value.tipoValorId == 2) {
                            setidPagoCompleto(value.valorCursoId)
                            setfechaInicioMatricula(new Date(value.fechaInicialVigencia).toLocaleDateString('en-ca'))
                            setfechaFinMatricula(new Date(value.fechaFinalVigencia).toLocaleDateString('en-ca'))
                            setDatosValor({
                                fechainicioPromtoPago: "",
                                fechaFinProntoPago: "",
                                valorProntoPago: 0,
                                fechainicioPagoCompleto: new Date(value.fechaInicialVigencia).toLocaleDateString('en-ca'),
                                fechaFinPagoCompleto: new Date(value.fechaFinalVigencia).toLocaleDateString('en-ca'),
                                valorPagoCompleto: 0,
                                fechainicioPagoExtemporaneo: "",
                                fechaFinPagoExtemporaneo: "",
                                valorPagoExtemporaneo: 0,
                                BpagoParcial: false,
                                valorPagoParcial: 0,
                            })
                            if (today > new Date(value.fechaFinalVigencia).toLocaleDateString('en-ca')) {
                                navigate("/courses")
                            }
                            initValues.valorPagar = 0
              initValues.valorcursoid = value.valorCursoId
                        }
                        if (value.tipoValorId == 5) {
                            setidCertificado(value.valorCursoId)
                            initValues.Vcertificado = value.valorCurso
                            initValues.Tcertificado = true
                            Vcertificado = value.valorCurso
                            setvalorc(value.valorCurso)
                            initValues.Idcertificado = value.valorCursoId
                        }

                    }

                )
            }

            setDatosCargados({
                codigo: resultado.data.codigoGrupo,
                nombre: resultado.data.nombreGrupo,
                cupo: resultado.data.cupo,
                Descripcion: resultado.data.descripcion,
                inicioCurso: new Date(resultado.data.fechaInicial).toLocaleDateString('en-ca'),
                finCurso: new Date(resultado.data.fechaFinal).toLocaleDateString('en-ca'),
                modalidad: resultado.data.modalidadId,
                programa: resultado.data.codigoPrograma,
                infoAdicionalGrupoId: resultado.data.infoAdicionalGrupoId,
            })
            initValues.modalidad= resultado.data.modalidadId
            setcargado(true)
        }
        CargarDatos()
        const Modalidad = async () => {
            const url = baseurl + environment.EducativeOffertURL + environment.Modalities
            const respuesta = await fetch(url)
            const resultado = await respuesta.json()
            const Lista = resultado.data.map(
                (tipoModalidad: any) => {
                    const objeto = {
                        value: +tipoModalidad.SCodigo,
                        label: tipoModalidad.SNombre
                    }
                    return objeto
                })
                initValues.modalidades=Lista
            setmodalidad(Lista)
        }
        Modalidad()
    }, [])
    const addCommas = (num: string) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    const removeNonNumeric = (num: { toString: () => string; }) => num.toString().replace(/[^0-9]/g, "");
    function thousandSeparator(valor: number) {
        return addCommas(removeNonNumeric(valor))
    }
    return (
        <>
            {!cargado ? (<Spinner animation={'border'}></Spinner>) : (<section className="w-100">

                <div className='pb-10 pb-lg-15'>
                    <div className='fv-row mb-10'>
                        <h2 style={{ textTransform: 'capitalize' }} className='fw-bolder d-flex align-items-center text-dark'>
                            {datosCargados.nombre}
                        </h2>
                        <div className='text-gray-400 fw-bold fs-6'>
                            Da el primer paso, asegura tu futuro!
                        </div>
                    </div>
                    <div>
                        <label className='text-dark fw-bolder d-block fs-4 mb-2'>Descripción:</label>
                        <textarea id='descripcion' className="form-control form-control-lg" value={datosCargados.Descripcion} disabled />
                    </div>
                </div>

                <div className='fv-row'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='fv-row mb-10'>
                                <label htmlFor="nombre" className='text-dark fw-bolder d-block fs-4 mb-2'>Modalidad:</label>
                                <Select id='modalidad'
                                    name='modalidad'
                                    value={modalidad.find(loadModalidad)}
                                    options={modalidad}
                                    isDisabled={true}
                                />
                            </div>
                            <div className='fv-row mb-10'>
                                <label className='text-dark fw-bolder d-block fs-4 mb-2'>Duración del programa:</label>
                                <label className='text-gray-400 fw-bold fs-6'>Fecha de inicio:</label>
                                <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={datosCargados.inicioCurso} type="date" disabled></input>
                                <label className='text-gray-400 fw-bold fs-6'>Fecha de finalización:</label>
                                <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={datosCargados.finCurso} type="date" disabled></input>
                            </div>
                        </div>

                        <div className='col-lg-6'>
                            <div className='fv-row mb-10'>
                                <label className='text-dark fw-bolder d-block fs-4 mb-2'>Valor:</label>
                                <div className="table-responsive">
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                        <thead className='thead-info'>
                                            <tr className='fw-bolder text-muted bg-light'>
                                                <th className='ps-4 rounded-start'>Del</th>
                                                <th>Hasta</th>
                                                <th>Valor</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {today <= datosValor.fechaFinProntoPago && <tr>
                                                <td>{datosValor.fechainicioPromtoPago}</td>
                                                <td>{datosValor.fechaFinProntoPago}</td>
                                                <td>{thousandSeparator(datosValor.valorProntoPago)}</td>
                                            </tr>}

                                            {today <= datosValor.fechaFinPagoCompleto && <tr>
                                                <td>{datosValor.fechainicioPagoCompleto}</td>
                                                <td>{datosValor.fechaFinPagoCompleto}</td>
                                                <td>{thousandSeparator(datosValor.valorPagoCompleto)}</td>
                                            </tr>}
                                            {today <= datosValor.fechaFinPagoExtemporaneo && <tr>
                                                <td>{datosValor.fechainicioPagoExtemporaneo}</td>
                                                <td>{datosValor.fechaFinPagoExtemporaneo}</td>
                                                <td>{thousandSeparator(datosValor.valorPagoExtemporaneo)}</td>
                                            </tr>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            {tieneHorario && <div className='fv-row mb-10'>
                                <label className='text-dark fw-bolder d-block fs-4 mb-2'>Horario:</label>
                                <div className="table-responsive">
                                    <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                        <thead className='fw-bolder text-muted bg-light'>
                                            <tr>
                                                <th className='ps-4 rounded-start'>Dia</th>
                                                <th>Hora Inicio</th>
                                                <th>Hora fin</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {horario.map((t: IHorario, i: number) => {
                                                if (!t.Beliminado) {
                                                    return (
                                                        <tr key={i}>
                                                            <th>{Semana.find(dia => +dia.value === +t.NroDia)!.label}</th>
                                                            <td>{t.HoraIni}</td>
                                                            <td>{t.HoraFin}</td>
                                                        </tr>
                                                    )
                                                }
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>

            </section>)}
        </>
    )
}

export { Step1 }
