import { data } from 'jquery';
import { FC, useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers';
import { Dropdown1 } from '../../../_metronic/partials';
import { ListsWidget1, ListsWidget2, ListsWidget3 } from '../../../_metronic/partials/widgets';
import { useLocation, useSearchParams } from 'react-router-dom'
import { useNavigate } from "react-router-dom";
type Props = {
  className: string
}
const Payu: FC = (className) => {
  const navigate = useNavigate();
  let location = useLocation();
  const params = new URLSearchParams(location.search)
  let dataPayu: any[] = []
  useEffect(()=>{
    document.getElementById("root")!.style.alignItems='center'
    document.getElementById("root")!.style.justifyContent='center'
    return()=>{
      document.getElementById("root")!.style.alignItems=''
    document.getElementById("root")!.style.justifyContent=''
    }
  })
  function goHome(){
    navigate("/courses")
  }
  return (
    <>
      <div className='w-25'>
        <div className={`card ${className}`}>
          {/* begin::Header */}
          <div className='card-header border-0 justify-content-center'>
            <h3 className='card-title fw-bolder text-dark'>Resumen de compra</h3>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body pt-2'>
            {/* begin::Item */}
            <div className="row">
              <div className='d-flex align-items-center mb-7 col-6'>
                {/* begin::Text */}
                <div className='flex-grow-1'>
                  <h4 className='text-dark fw-bolder  fs-6'>
                    ID
                  </h4>
                  <span className='text-muted d-block fw-bold'>{params.get("merchantId")}</span>

                </div>
                {/* end::Text */}
              </div>
              {/* end::Item */}
              <div className='d-flex align-items-center mb-7 col-6'>
                {/* begin::Text */}
                <div className='flex-grow-1'>
                  <h4 className='text-dark fw-bolder  fs-6'>
                    Referencia
                  </h4>
                  <span className='text-muted d-block fw-bold'>{params.get("referenceCode")}</span>
                </div>
                {/* end::Text */}
              </div>
              <div className='d-flex align-items-center mb-7 col-6'>
                {/* begin::Text */}
                <div className='flex-grow-1'>
                  <h4 className='text-dark fw-bolder  fs-6'>
                    Medio de pago
                  </h4>
                  <span className='text-muted d-block fw-bold'>{params.get("lapPaymentMethodType")}</span>
                </div>
                {/* end::Text */}
              </div>
              <div className='d-flex align-items-center mb-7 col-6'>
                {/* begin::Text */}
                <div className='flex-grow-1'>
                  <h4 className='text-dark fw-bolder  fs-6'>
                    Estado
                  </h4>
                  <span className='text-muted d-block fw-bold'>{params.get("lapResponseCode")}</span>
                </div>
                {/* end::Text */}
              </div>
              <div className='d-flex align-items-center mb-7 col-6'>
                {/* begin::Text */}
                <div className='flex-grow-1'>
                  <h4 className='text-dark fw-bolder  fs-6'>
                    Valor
                  </h4>
                  <span className='text-muted d-block fw-bold'>{params.get("TX_VALUE")} {params.get("currency")}</span>
                </div>
                {/* end::Text */}
              </div>
              <div className='d-flex align-items-center mb-7 col-6'>
                {/* begin::Text */}
                <div className='flex-grow-1'>
                  <h4 className='text-dark fw-bolder  fs-6'>
                    Fecha
                  </h4>
                  <span className='text-muted d-block fw-bold'>{params.get("processingDate")}</span>
                </div>
                {/* end::Text */}
              </div>
            </div>
            <div className="justify-content-center text-center mt-5">
              <button onClick={goHome} className="btn btn-success py-3">volver</button>
            </div>
          </div>
          {/* end::Body */}
        </div>
      </div>
    </>
  )
}

export { Payu }
