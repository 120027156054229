import * as Yup from 'yup'

export interface ICreateAccount {
  NIdentificacion: string
  Nombre: string
  Apellidos: string
  FNaciemiento: string
  Email: string
  Telefono: string
  Sexo: string
  Municipio: string
  NivelAcademico: string
  medio: string
  adjDocPersona: any
  adjDocResponsable: any
  adjFoto: any
  adjDiploma: any
  adjCertificadoEps: any
  institucionEducativaId: number
  sectorEconomicoId: number
  trabaja: boolean
  textoContrato: string
  fechaContrato: string
  ipContrato: string
  codigoGrupo: string
  terminosYCondiciones: boolean
  habeasData: boolean
  esParcial: string
  infoAdicionalGrupoId: number
  anioGraduacion: number
  valorPagar: number
  mayor: boolean
  valorcursoid: number
  valorParcial: number
  nombrePparcial :string
  cedulaPparcial: string
  valorMinParcial: number
  Tcertificado: boolean
  Vcertificado : number
  Bcertificado: string
  Idcertificado: number
  idCupon:string
  valorCupon:number
  emailConfirmation: string
  tipo:boolean,
  modalidad:number,
  TBPagoParcial:boolean,
  modalidades: any[],
  nombreCurso:string,
  FechaInicio:string,
  FechaFin:string

}
const Obligatorio = "Campo Obligatorio"
const hoy = new Date().getFullYear()
const today = new Date();
today.setMonth(today.getMonth()-120);
const createAccountSchemas = [
  Yup.object({
    codigoGrupo: Yup.string(),
    infoAdicionalGrupoId: Yup.number(),
  }),
  Yup.object({
    NIdentificacion: Yup.string().required(Obligatorio).label('Numero de identificacion').matches(/^[0-9]+$/, "Identificacion invalido").min(6,"Identificacion invalida").max(16,"Identificacion invalida"),
    Nombre: Yup.string().required(Obligatorio).label('Nombre'),
    Apellidos: Yup.string().required(Obligatorio).label('Apellidos'),
    Email: Yup.string().email("Correo Invalido").required(Obligatorio).label('Correo Electronico'),
    emailConfirmation: Yup.string().required(Obligatorio)
     .oneOf([Yup.ref('Email')], 'Los correos no coinciden'),
    FNaciemiento: Yup.date().required(Obligatorio).label('Fecha de nacimiento').max(today,"Fecha invalida"),
    Telefono: Yup.string().required(Obligatorio).label('Telefono de contacto').matches(/^[0-9]+$/, "Telefono invalido").min(7,"telefono invalido").max(11,"telefono invalido"),
    Sexo: Yup.string().required(Obligatorio).label('Sexo'),
    Municipio: Yup.string().required(Obligatorio).label('Municipio'),
    NivelAcademico: Yup.string().required(Obligatorio).label('NivelAcademico'),
    medio: Yup.string(),
    trabaja: Yup.string(),
    anioGraduacion: Yup.number().label("Año de Graduacion").max(hoy),
    mayor: Yup.boolean(),
    sectorEconomicoId: Yup.number(),
  }),
  Yup.object().shape({
    adjDocPersona: Yup.mixed().required(Obligatorio),
    adjDocResponsable : Yup.mixed(),
    adjFoto: Yup.mixed(),
    adjDiploma: Yup.mixed(),
    adjCertificadoEps: Yup.mixed(),
  }),
  Yup.object({
    valorPagar: Yup.string(),
    cedulaPparcial : Yup.string().label('Numero de identificacion').matches(/^[0-9]+$/, "Identificacion invalido").min(6,"Identificacion invalida").max(16,"Identificacion invalida"),
    nombrePparcial : Yup.string(),
    ipContrato: Yup.string(),
    esParcial: Yup.string(),
    valorParcial: Yup.number(),
    textoContrato :Yup.string(),
    fechaContrato :Yup.string(),
    valorcursoid : Yup.number(),
    terminosYCondiciones: Yup.bool().required(Obligatorio),
    habeasData: Yup.bool().required(Obligatorio),
    valorMinParcial : Yup.number(),
    Tcertificado: Yup.boolean(),
    Vcertificado: Yup.number(),
    Bcertificado: Yup.string(),
    Idcertificado:Yup.number(),
    idCupon: Yup.string(),
    valorCupon: Yup.number()
  }),
]

const inits: ICreateAccount = {
  NIdentificacion: "",
  Nombre: '',
  Apellidos: '',
  Email: '',
  emailConfirmation:'',
  FNaciemiento: '',
  Telefono: "",
  Sexo: '',
  Municipio: '',
  NivelAcademico: '',
  medio: "",
  adjDocPersona: undefined,
  adjDocResponsable: undefined,
  adjFoto: undefined,
  adjDiploma: undefined,
  adjCertificadoEps: undefined,
  institucionEducativaId: 0,
  sectorEconomicoId: 0,
  trabaja: false,
  textoContrato:  "",
  fechaContrato: "",
  ipContrato: "",
  codigoGrupo: "",
  terminosYCondiciones: false,
  habeasData: false,
  esParcial: "false",
  infoAdicionalGrupoId: 0,
  anioGraduacion: 0,
  valorPagar: 0,
  mayor:false,
  valorcursoid : 0,
  valorParcial: 0,
  nombrePparcial : "",
  cedulaPparcial: "",
  valorMinParcial :0,
  Tcertificado: false,
  Vcertificado: 0,
  Bcertificado : "false",
  Idcertificado: 0,
  idCupon:"",
  valorCupon:0,
  tipo:false,
  modalidad:0,
  TBPagoParcial:false,
  modalidades:[],
  nombreCurso:"",
  FechaFin:"",
  FechaInicio:""
}

export { createAccountSchemas, inits }
