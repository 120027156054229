import React, { FC, useEffect, useState } from 'react'
import { Field, ErrorMessage } from 'formik'
import { environment } from '../../../../../environments/environment';
import { useLocation } from 'react-router-dom';
import { createAccountSchemas, ICreateAccount, inits } from '../CreateAccountWizardHelper'
import PictureInput from '../PictureInput';
import pdfInput from '../pdfInput';

const Step3: FC = () => {
    const [initValues] = useState<ICreateAccount>(inits)
    const baseurl = environment.acamoodURL
    let location = useLocation();
    const path = location.pathname.split('/')[location.pathname.split("/").length-1]
    const url = baseurl + environment.EducativeOffertURL + environment.OffertByCod + path
    
    return (

        <>
            <div className='w-100'>
                <div className='pb-8 pb-lg-10'>
                    <h2 className='fw-bolder text-dark'>Documentación</h2>

                    <div className='text-gray-400 fw-bold fs-6'>
                        A continuación seleccione los archivos que contienen los documentos pedidos:                    .
                    </div>
                </div>

                <div className='mb-0'>
                    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                        <div className='d-flex flex-stack flex-grow-1'>
                            <div className='fw-bold'>
                                <h4 className='text-gray-800 fw-bolder'>Documento de identidad</h4>
                                <div className='fs-6 text-gray-600'>
                                    Documento de Identidad (Cédula ó Tarjeta de identidad)
                                </div>
                                <div className="text-danger">
                                <ErrorMessage name='adjDocPersona' />
                            </div>
                            </div>
                            <Field type="file" name="adjDocPersona" component={pdfInput}/>
                        </div>
                    </div>
                    
                    {!initValues.mayor &&  <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                        <div className='d-flex flex-stack flex-grow-1'>
                            <div className='fw-bold'>
                                <h4 className='text-gray-800 fw-bolder'>Documento de identidad del acudiente</h4>
                                <div className='fs-6 text-gray-600'>
                                    Documento de Identidad (Cédula)
                                </div>
                            </div>
                            <Field type="file" name="adjDocResponsable" component={pdfInput}/>
                            <div className="text-danger">
                                <ErrorMessage name='adjDocResponsable' />
                            </div>
                        </div>
                    </div>}
                    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                        <div className='d-flex flex-stack flex-grow-1'>
                            <div className='fw-bold'>
                                <h4 className='text-gray-800 fw-bolder'>Foto</h4>
                                <div className='fs-6 text-gray-600'>
                                    Opcional
                                </div>
                            </div>
                            <Field type="file" name="adjFoto" component={PictureInput}/>
                            <div className="text-danger">
                                <ErrorMessage name='adjFoto' />
                            </div>
                        </div>
                    </div>

                    {initValues.tipo && <div>
                    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                        <div className='d-flex flex-stack flex-grow-1'>
                            <div className='fw-bold'>
                                <h4 className='text-gray-800 fw-bolder'>Diploma ó Acta de grado</h4>
                            </div>
                            <Field type="file" name="adjDiploma" component={PictureInput}/>
                            <div className="text-danger">
                                <ErrorMessage name='adjDiploma' />
                            </div>
                        </div>
                    </div>
                    <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                        <div className='d-flex flex-stack flex-grow-1'>
                            <div className='fw-bold'>
                                <h4 className='text-gray-800 fw-bolder'>Certificado de la EPS</h4>
                                <div className='fs-6 text-gray-600'>
                                    Opcional
                                </div>
                            </div>
                            <Field type="file" name="adjCertificadoEps" component={PictureInput}/>
                            <div className="text-danger">
                                <ErrorMessage name='adjCertificadoEps' />
                            </div>
                        </div>
                    </div>
                    </div>}
                </div>
            </div></>
    )
}

export { Step3 }
