import React, { useEffect, useState } from 'react'
import { BsFillPencilFill, BsFillPlusCircleFill } from "react-icons/bs";
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Spinner } from 'react-bootstrap'
import Select from 'react-select'
import { environment } from '../../../../environments/environment'
import { IPost } from './IPost';
import Pagination from '../Pagination';
import { useLocation } from 'react-router-dom';
import exportFromJSON from 'export-from-json' 
interface IHorario {
    InfoAdicionalGrupoId: number,
    horarioId: string,
    Slabel: string,
    HoraIni: string,
    HoraFin: string,
    NroDia: number,
    Beliminado: boolean
}
interface ICupon {
    valorCursoId: string,
    Activo: string,
    Codigo: string,
    Valor: number,
    Cantidad: number,
    Beliminado: boolean
}
interface ICurso {
    codigo: string,
    nombreGrupo: string,
    nivel: string,
    tipoPrograma: string,
    tipoModalidad: string,
}
const baseurl = environment.apiURL


export function OfertList() {
    
    
    const [cargado, setcargado] = useState(false)
    const [data, setData] = useState<IPost[]>([]);
    const [displayedData, setDisplayedData] = useState<IPost[]>([]);
    const itemsPerPage = 10;
    const [allPages,setAllpage] = useState(Math.ceil(data.length / itemsPerPage))
    const [modal, setModal] = useState(false)
    const [cursos, setcursos] = useState([])
    const [nivel, setNivel] = useState([])
    const [modalidad, setmodalidad] = useState([])
    const [cupones, setCupones] = useState<ICupon[]>([])
    const [pagoParcial, setPagoParcial] = useState(false)
    const [idProntopago, setidProntopago] = useState(0);
    const [idPagoCompleto, setidPagoCompleto] = useState(0);
    const [idPagoExtempo, setidPagoExtempo] = useState(0);
    const [idPagoParcial, setidPagoParcial] = useState(0);
    const [idCertificado, setidCertificado] = useState(0);
    const [valorc, setvalorc] = useState(0);
    const [tieneHorario, settieneHorario] = useState(false)
    const [fechaInicioCurso, setFechaInicioCurso] = useState("")
    const [fechaInicioMatricula, setfechaInicioMatricula] = useState("")
    const [fechaFinMatricula, setfechaFinMatricula] = useState("")
    const [fechaInicioProntoPago, setfechaInicioProntoPago] = useState("")
    const [fechaInicioPagoCompleto, setfechaInicioPagoCompleto] = useState("")
    const [fechaInicioPagoExtempo, setfechaInicioPagoExtempo] = useState("")
    const [horario, setHorario] = useState<IHorario[]>([])
    const baseurl = environment.acamoodURL
    let location = useLocation();
    const [publicado, setpublicado] = useState(false)
    const [SModalidad,setSModalidad]= useState("")
    const [SNivel,setSNivel]=useState("")
    const [datosValor, setDatosValor] = useState({
        fechainicioPromtoPago: '',
        fechaFinProntoPago: '',
        valorProntoPago: 0,
        fechainicioPagoCompleto: '',
        fechaFinPagoCompleto: '',
        valorPagoCompleto: 0,
        fechainicioPagoExtemporaneo: '',
        fechaFinPagoExtemporaneo: '',
        valorPagoExtemporaneo: 0,
        BpagoParcial: false,
        valorPagoParcial: 0
    })
    const Semana = [
        { value: 0, label: 'Domingo' },
        { value: 1, label: 'Lunes' },
        { value: 2, label: 'Martes' },
        { value: 3, label: 'Miercoles' },
        { value: 4, label: 'Jueves' },
        { value: 5, label: 'Viernes' },
        { value: 6, label: 'Sabado' },
    ]
    const [datosCargados, setDatosCargados] = useState({
        codigo: "",
        nombre: "",
        cupo: 0,
        programa: "",
        modalidad: 0,
        inicioCurso: "",
        finCurso: "",
        Descripcion: "",
        infoAdicionalGrupoId: 0
    })
    const fetchData = async (url: string): Promise<IPost[]> => {
        const respuesta = await fetch(url)
        const resultado = await respuesta.json()
        const ListaCursos = resultado.data.map(
            (curso: any) => {
                const objeto = {
                    codigo: curso.codigoGrupo,
                    nombreGrupo: curso.nombreGrupo,
                    nivel: curso.tipoPrograma,
                    tipoModalidad: curso.tipoModalidad,
                }
                return objeto
            })
            setcargado(true)
        return ListaCursos
    }
    const onPageChange = (page: number = 1, buscar =busqueda,modalidad=SModalidad,nivel=SNivel) => {
        const startItem = (page - 1) * itemsPerPage;
        const endItem = page * itemsPerPage;
        let aux:any[] = data.filter((elemento) => {
            if (nivel && modalidad && buscar) {
                if(elemento.nombreGrupo.toString().toLowerCase().includes(buscar.toLowerCase())
                && elemento.tipoModalidad.toString().toLowerCase().includes(modalidad.toLowerCase())
                && elemento.nivel.toString().toLowerCase().includes(nivel.toLowerCase())){
                    return elemento
                }
            }else if(modalidad && nivel && !buscar){
                if(elemento.tipoModalidad.toString().toLowerCase().includes(modalidad.toLowerCase())
                && elemento.nivel.toString().toLowerCase().includes(nivel.toLowerCase())){
                    return elemento
                }
            }else if(nivel && buscar && !modalidad){
                if(elemento.nombreGrupo.toString().toLowerCase().includes(buscar.toLowerCase())
                && elemento.nivel.toString().toLowerCase().includes(nivel.toLowerCase())){
                    return elemento
                }
            }else if(modalidad && buscar && !nivel){
                if(elemento.nombreGrupo.toString().toLowerCase().includes(buscar.toLowerCase())
                && elemento.tipoModalidad.toString().toLowerCase().includes(modalidad.toLowerCase())){
                    return elemento
                }
            }else if(modalidad && !buscar && !nivel){
                if( elemento.tipoModalidad.toString().toLowerCase().includes(modalidad.toLowerCase())){
                    return elemento
                }
            }else if(!modalidad && !buscar && nivel){
                if(elemento.nivel.toString().toLowerCase().includes(nivel.toLowerCase())){
                    return elemento
                }
            }else if(!modalidad && buscar && !nivel){
                if(elemento.nombreGrupo.toString().toLowerCase().includes(buscar.toLowerCase())){
                    return elemento
                }
            }else{
                return elemento
            }
        })
        
        setDisplayedData(aux.slice(startItem, endItem))
        setAllpage(Math.ceil(aux.length / itemsPerPage))
        
    }
    const Modalidad = async () => {
        const url = baseurl + environment.EducativeOffertURL + environment.Modalities
        const respuesta = await fetch(url)
        const resultado = await respuesta.json()
        const Lista = resultado.data.map(
            (tipoModalidad: any) => {
                const objeto = {
                    value: +tipoModalidad.SCodigo,
                    label: tipoModalidad.SNombre
                }
                return objeto
            })
        setmodalidad(Lista)
    }
    const CargarDatos = async (codigo: string) => {

        const respuesta = await fetch(baseurl + environment.EducativeOffertURL + environment.OffertByCod + codigo)
        const resultado = await respuesta.json()
        console.log(resultado.data)
        if (resultado.data.estadoId == 2) {
            setpublicado(true)
        }
        let ifechainicioPromtoPago = ""
        let ifechaFinProntoPago = ""
        let ivalorProntoPago = 0
        let ifechainicioPagoCompleto = ""
        let ifechaFinPagoCompleto = ""
        let ivalorPagoCompleto = 0
        let ifechainicioPagoExtemporaneo = ""
        let ifechaFinPagoExtemporaneo = ""
        let ivalorPagoExtemporaneo = 0
        let Vcertificado = 0
        let Vpagoparcial = 0
        let datosh: IHorario[] = []
        if (resultado.data.tieneHorario) {
            resultado.data.Horario.map(
                (valor: any) => {
                    datosh = [...datosh, {
                        InfoAdicionalGrupoId: resultado.data.infoAdicionalGrupoId,
                        horarioId: valor.horarioId,
                        Slabel: "",
                        HoraIni: valor.HoraIni,
                        HoraFin: valor.HoraFin,
                        NroDia: valor.NroDia,
                        Beliminado: false
                    }]
                })
            settieneHorario(true)
            setHorario(datosh)
        }
        let datosc: ICupon[] = []
        if (resultado.data.esPago) {

            resultado.data.valor.map(
                (valor: any) => {
                    if (valor.tipoValorId == 1) {
                        setidProntopago(+valor.valorCursoId)
                        ifechainicioPromtoPago = valor.fechaInicialVigencia
                        ifechaFinProntoPago = valor.fechaFinalVigencia
                        ivalorProntoPago = valor.valorCurso

                    }
                    if (valor.tipoValorId == 2) {
                        setidPagoCompleto(+valor.valorCursoId)
                        setfechaInicioPagoCompleto(new Date(valor.fechaInicialVigencia).toLocaleDateString('en-ca'))
                        ifechainicioPagoCompleto = valor.fechaInicialVigencia
                        ifechaFinPagoCompleto = valor.fechaFinalVigencia
                        ivalorPagoCompleto = valor.valorCurso
                    }
                    if (valor.tipoValorId == 3) {
                        setfechaInicioPagoExtempo(new Date(valor.fechaInicialVigencia).toLocaleDateString('en-ca'))
                        setidPagoExtempo(+valor.valorCursoId)
                        ifechainicioPagoExtemporaneo = valor.fechaInicialVigencia
                        ifechaFinPagoExtemporaneo = valor.fechaFinalVigencia
                        ivalorPagoExtemporaneo = valor.valorCurso

                    }
                    if (valor.tipoValorId == 6) {
                        setidPagoParcial(valor.valorCursoId)
                        Vpagoparcial = valor.valorParcial
                    }
                    setDatosValor({
                        fechainicioPromtoPago: new Date(ifechainicioPromtoPago).toLocaleDateString('en-ca'),
                        fechaFinProntoPago: new Date(ifechaFinProntoPago).toLocaleDateString('en-ca'),
                        valorProntoPago: ivalorProntoPago,
                        fechainicioPagoCompleto: new Date(ifechainicioPagoCompleto).toLocaleDateString('en-ca'),
                        fechaFinPagoCompleto: new Date(ifechaFinPagoCompleto).toLocaleDateString('en-ca'),
                        valorPagoCompleto: ivalorPagoCompleto,
                        fechainicioPagoExtemporaneo: new Date(ifechainicioPagoExtemporaneo).toLocaleDateString('en-ca'),
                        fechaFinPagoExtemporaneo: new Date(ifechaFinPagoExtemporaneo).toLocaleDateString('en-ca'),
                        valorPagoExtemporaneo: ivalorPagoExtemporaneo,
                        BpagoParcial: pagoParcial,
                        valorPagoParcial: Vpagoparcial,
                    })
                }

            )
            setCupones(datosc);
        } else {
            resultado.data.valor.map(
                (value: any) => {
                    if (value.tipoValorId == 2) {
                        setidPagoCompleto(value.valorCursoId)
                        setfechaInicioMatricula(new Date(value.fechaInicialVigencia).toLocaleDateString('en-ca'))
                        setfechaFinMatricula(new Date(value.fechaFinalVigencia).toLocaleDateString('en-ca'))
                    }
                    if (value.tipoValorId == 5) {
                        setidCertificado(value.valorCursoId)

                        Vcertificado = value.valorCurso
                        setvalorc(value.valorCurso)
                    }

                }

            )
        }

        setDatosCargados({
            codigo: resultado.data.codigoGrupo,
            nombre: resultado.data.nombreGrupo,
            cupo: resultado.data.cupo,
            Descripcion: resultado.data.descripcion,
            inicioCurso: new Date(resultado.data.fechaInicial).toLocaleDateString('en-ca'),
            finCurso: new Date(resultado.data.fechaFinal).toLocaleDateString('en-ca'),
            modalidad: resultado.data.modalidadId,
            programa: resultado.data.codigoPrograma,
            infoAdicionalGrupoId: resultado.data.infoAdicionalGrupoId,
        })
    }
    const Nivel = async () => {
        const url = baseurl + environment.EducativeOffertURL + environment.programTypes
        const respuesta = await fetch(url)

        const resultado = await respuesta.json()
        const Lista = resultado.data.map(
            (nivel: any) => {
                const objeto = {
                    value: nivel.CodPrograma,
                    label: nivel.tipoPrograma
                }
                return objeto
            })
        setNivel(Lista)
    }

    function abrirmodal() {
        setModal(!modal)
    }
   
    const onchangeselectModalidad = (event: any) => {
        if(event != null){
            setSModalidad(event.label)
            onPageChange(1,busqueda,event.label,SNivel)
        }else{
            setSModalidad("")
            onPageChange(1,busqueda,"",SNivel)
        }
        
    }
    const onchangeselectNivel = (event: any) => {
        if(event != null){
            setSNivel(event.label)
            onPageChange(1,busqueda,SModalidad,event.label)
        }else{
            setSNivel("")
            onPageChange(1,busqueda,SModalidad,"")
        }
        
    }
    useEffect(() => {
        const url = baseurl + environment.EducativeOffertURL + environment.PublicGroups;
        fetchData(url).then(data => setData(data));
        onPageChange()
        Modalidad()
        Nivel()
    }, [data.length])
    const [busqueda, setBusqueda] = useState("")
    function buscar(event: { target: { value: React.SetStateAction<string>; }; }){
        setBusqueda(event.target.value)
        onPageChange(1,event.target.value.toString(),SModalidad,SNivel)
    }

    function loadModalidad(codigo: { value: string }) {
        return +codigo.value === +datosCargados.modalidad;
    }
    function abrirModal1(codigo: string) {
        CargarDatos(codigo)
        setModal(true)
    }
    return (
        <>
           {!cargado ? (<Spinner animation={'border'}></Spinner>):( <div className='card mb-5 mb-xl-12'>
                <div className="mx-5 mt-3">
                    <div className="input-group rounded">
                        <input type="search" className="form-control rounded" value={busqueda} placeholder="Buscar por palabra clave" onChange={buscar} aria-label="Search" aria-describedby="search-addon" />
                        <span className="input-group-text border-0" id="search-addon">
                            <i className="fas fa-search"></i>
                        </span>
                    </div>
                    <section className="py-5">
                        <div className="row">
                            <div className="col">
                                <span className="card-label fw-bolder fs-3 mb-1">Modalidad:</span>
                                <Select placeholder="Seleccionar" options={modalidad} onChange={onchangeselectModalidad} isClearable/>
                            </div>
                            <div className="col">
                                <span className="card-label fw-bolder fs-3 mb-1">Programa:</span>
                                <Select placeholder="Seleccionar" options={nivel} onChange={onchangeselectNivel} isClearable/>
                            </div>
                        </div>
                        <div className="table-responsive mt-3">
                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                <thead>
                                    <tr className="fw-bolder text-muted bg-light">
                                        <td className="ps-4 min-w-325px rounded-start">Nombre</td>
                                        <td>Modalidad</td>
                                        <td>Programa</td>
                                        <td>Opciones</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        displayedData.map((item: IPost) => {
                                            return (
                                                <tr key={item.codigo}>
                                                    <th style={{ textTransform: 'capitalize' }} className="text-dark fw-bolder text-hover-primary mb-1 fs-6">{item.nombreGrupo}</th>
                                                    <th>{item.tipoModalidad}</th>
                                                    <th>{item.nivel}</th>
                                                    <th>
                                                        <div className="row">
                                                            <div className="col">
                                                                <a type="button" className="badge badge-light-primary fs-8 fw-bolder" onClick={() => abrirModal1(item.codigo)}>Ver <BsFillPlusCircleFill /> </a>
                                                            </div>
                                                            <div className="col">
                                                                <a type="button" className="badge badge-light-success fs-8 fw-bolder" href={'matricular/' + item.codigo}>Matricularse</a>
                                                            </div>
                                                        </div>
                                                    </th>

                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <Pagination allPagesNumber={allPages} itemsPerPage={10} itemsNumber={data.length} pageChange={onPageChange} />
                        </div>

                    </section>
                </div>
            </div>)}

            <Modal show={modal}>
                <ModalHeader closeButton onHide={abrirmodal}> <h2 className='fw-bolder d-flex align-items-center text-dark'>
                    {datosCargados.nombre}
                </h2></ModalHeader>
                <ModalBody>
                    <section className="w-100">
                        <div className='pb-10 pb-lg-15'>
                            <div>
                                <label className='text-dark fw-bolder d-block fs-4 mb-2'>Descripción:</label>
                                <textarea id='descripcion' className="form-control form-control-lg" value={datosCargados.Descripcion} disabled />
                            </div>
                        </div>
                        <div className='fv-row'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='fv-row mb-6'>
                                        <label htmlFor="nombre" className='text-dark fw-bolder d-block fs-4 mb-2'>Modalidad:</label>
                                        <Select id='modalidad'
                                            name='modalidad'
                                            value={modalidad.find(loadModalidad)}
                                            options={modalidad}
                                            isDisabled={true}
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                <div className='fv-row mb-6'>
                                    <label className='text-dark fw-bolder d-block fs-4 mb-2'>Duración del programa:</label>
                                    <label className='text-gray-400 fw-bold fs-6'>Fecha de inicio:</label>
                                    <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={datosCargados.inicioCurso} type="date" disabled></input>
                                    <label className='text-gray-400 fw-bold fs-6'>Fecha de finalización:</label>
                                    <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" value={datosCargados.finCurso} type="date" disabled></input>
                                    </div>
                                </div>
                                <div className='col-lg-12'>
                                    <div className='fv-row mb-10'>
                                        <label className='text-dark fw-bolder d-block fs-4 mb-2'>Valor:</label>
                                        <div className="table-responsive">
                                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                                <thead className='thead-info'>
                                                    <tr className='fw-bolder text-muted bg-light'>
                                                        <th className='ps-4 rounded-start'>Del</th>
                                                        <th>Hasta</th>
                                                        <th>Valor</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>{datosValor.fechainicioPromtoPago}</td>
                                                        <td>{datosValor.fechaFinProntoPago}</td>
                                                        <td>{datosValor.valorProntoPago}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{datosValor.fechainicioPagoCompleto}</td>
                                                        <td>{datosValor.fechaFinPagoCompleto}</td>
                                                        <td>{datosValor.valorPagoCompleto}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>{datosValor.fechainicioPagoExtemporaneo}</td>
                                                        <td>{datosValor.fechaFinPagoExtemporaneo}</td>
                                                        <td>{datosValor.valorPagoExtemporaneo}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                    <div className='col-lg-12'>
                                    {tieneHorario && <div className='fv-row mb-10'>
                                        <label className='text-dark fw-bolder d-block fs-4 mb-2'>Horario:</label>
                                        <div className="table-responsive">
                                            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                                <thead className='fw-bolder text-muted bg-light'>
                                                    <tr>
                                                        <th className='ps-4 rounded-start'>Dia</th>
                                                        <th>Hora Inicio</th>
                                                        <th>Hora fin</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {horario.map((t: IHorario, i: number) => {
                                                        if (!t.Beliminado) {
                                                            return (
                                                                <tr key={i}>
                                                                    <th>{Semana.find(dia => +dia.value === +t.NroDia)!.label}</th>
                                                                    <td>{t.HoraIni}</td>
                                                                    <td>{t.HoraFin}</td>
                                                                </tr>
                                                            )
                                                        }
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>

                    </section>
                </ModalBody>
            </Modal>
        </>
    )
}