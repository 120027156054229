import React from 'react'
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'
import {useIntl} from 'react-intl'

export function MenuInner() {
  const intl = useIntl()
  return (
    <>
      <MenuItem title='Escritorio'  to='/dashboard' />
      <MenuInnerWithSub
        title='Campus Virtual'
        to='/academico'
        menuPlacement='bottom-start'
        menuTrigger='click'
        
      >
        {/* PAGES */}
        <MenuInnerWithSub
          title='Académico'
          to='/academico/OfertaEducativa'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Oferta Educativa'
            to='/academico/OfertaEducativa'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/academico/OfertaEducativa/Oferta' title='Oferta' hasBullet={true} />
            <MenuItem to='/academico/OfertaEducativa/CrearOferta' title='Crear oferta' hasBullet={true} />
          </MenuInnerWithSub>
          
        </MenuInnerWithSub>
        <MenuInnerWithSub
          title='reportes'
          to='/reportes'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='ver reportes'
            to='/reportes'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/reportes' title='Ver reportes' hasBullet={true} />
          </MenuInnerWithSub>
          
        </MenuInnerWithSub>
        {/* <MenuInnerWithSub
          title='Matriculacion'
          to='/academico/wizards'
          fontIcon='bi-archive'
          hasArrow={true}
          menuPlacement='right-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuInnerWithSub
            title='Matricular'
            to='/academico/wizards'
            hasArrow={true}
            hasBullet={true}
            menuPlacement='right-start'
            menuTrigger={`{default:'click', lg: 'hover'}`}
          >
            <MenuItem to='/academico/wizards/OfertList' title='Ofertas Disponibles' hasBullet={true} />
          </MenuInnerWithSub>
        </MenuInnerWithSub> */}
      </MenuInnerWithSub>
    </>
  )
}
