import React, { FC, useState, useEffect } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup } from 'react-bootstrap'
import swal from 'sweetalert';
import { Field, ErrorMessage } from 'formik'
import { environment } from '../../../../../environments/environment';
import { useLocation } from 'react-router-dom';
import Select from 'react-select'

import { createAccountSchemas, ICreateAccount, inits } from '../CreateAccountWizardHelper'
interface IHorario {
  InfoAdicionalGrupoId: number,
  horarioId: string,
  Slabel: string,
  HoraIni: string,
  HoraFin: string,
  NroDia: number,
  Beliminado: boolean
}
interface ICupon {
  valorCursoId: string,
  Activo: string,
  Codigo: string,
  Valor: number,
  Cantidad: number,
  Beliminado: boolean
}
const Step4: FC = () => {
  const [initValues] = useState<ICreateAccount>(inits)
  const [ModalTerminos, setModalTerminos] = useState(false)
  const [ModalData, setModalData] = useState(false)
  const [Nombre, SetNombre] = useState("")
  const [NombreResponsable, SetNombreResponsable] = useState("")
  const [DocumentoResponsable, SetDocumentoResponsable] = useState("")
  const [Apellidos, SetApellido] = useState("")
  const [Documento, SetDocuemtno] = useState("")
  const [MayorEdad, setMayorEdad] = useState(false)
  const [modalidad, setmodalidad] = useState([])
 

  
 
  const baseurl = environment.acamoodURL
  let location = useLocation();
  const path = location.pathname.split('/')[location.pathname.split("/").length-1]
  const url = baseurl + environment.EducativeOffertURL + environment.OffertByCod + path
  function loadModalidad(codigo: { value: string }) {
    return +codigo.value === +initValues.modalidad;
  }
  const [valorCupon, setValorCupon] = useState(0)
  const [idCupon, setIdCupon] = useState(0)
  async function validarCupon() {
    let codigo = (document.getElementById('cupon') as HTMLInputElement).value;
    const respuesta = await fetch(baseurl + environment.CommonUrl + environment.cupon,
      {
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          infoAdiccionalGrupoId: initValues.infoAdicionalGrupoId,
          codigo: codigo,
        })
      })
    const resultado = await respuesta.json()
    
    if (resultado.valorDescuento != null) {
      setValorCupon(resultado.valorDescuento)
      setIdCupon(resultado.valorCursoId)
      initValues.idCupon = resultado.valorCursoId;
      initValues.valorCupon = resultado.valorDescuento;
    } else {
      swal("Algo ha salido mal", "Cupon invalido", "error")
    }
  }
  useEffect(() => {
    const Modalidad = async () => {
      const url = baseurl + environment.EducativeOffertURL + environment.Modalities
      const respuesta = await fetch(url)
      const resultado = await respuesta.json()
      const Lista = resultado.data.map(
        (tipoModalidad: any) => {
          const objeto = {
            value: +tipoModalidad.SCodigo,
            label: tipoModalidad.SNombre
          }
          return objeto
        })
      setmodalidad(Lista)
    }
    Modalidad()

  }, [])
  const [modalContrato, setModalContrato] = useState(false)
  const [certificacionActivo, setPagoCertificadoActivo] = useState(false)
  const [certificado, setcertificado] = useState(false)

  const certificadoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setcertificado(event.target.value === 'true')
  };
  function abrirContrato() {
    if (initValues.mayor) {
      SetNombreResponsable(initValues.Nombre.concat(" ") + initValues.Apellidos)
      SetNombre(initValues.Nombre)
      SetApellido(initValues.Apellidos)
      SetDocuemtno(initValues.NIdentificacion.toString())
      
      SetDocumentoResponsable(initValues.NIdentificacion)
    }
    setModalContrato(!modalContrato)
  }
  function abrirterminos() {
    setModalTerminos(!ModalTerminos)
  }
  const [esMenorActivo, setEsMenorActivo] = useState(false)
  const esMenorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEsMenorActivo(event.target.value === 'true')
    if (!esMenorActivo) {
      mostrarValoresMenor()
    } else {
      ocultarValoresMenor()
    }
  };

  function mostrarValoresMenor() {
    document.getElementById("esMenorValor")!.style.display = 'block';
  }
  function ocultarValoresMenor() {
    document.getElementById("esMenorValor")!.style.display = 'none';
  }

  function mostrarValorpagoParcial() {
    document.getElementById("pagoParcialValor")!.style.display = 'block';
  }
  function ocultarValorpagoParcial() {
    document.getElementById("pagoParcialValor")!.style.display = 'none';
  }
  const [PagoParcialActivo, setPagoParcialActivo] = useState(false)
  const pagoParcialChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPagoParcialActivo(event.target.value === 'true')
    if (!certificacionActivo) {
      mostrarValorpagoParcial()
    } else {
      ocultarValorpagoParcial()
    }
  };
  function NResponsable(event: { target: { value: React.SetStateAction<string>; }; }) {
    SetNombreResponsable(event.target.value)
  }
  const [valorP, setvalorP] = useState(0)
  function valorparcial(event: { target: { value: React.SetStateAction<string>; }; }) {
    setvalorP(+event.target.value)
  }
  function DResponsable(event: { target: { value: React.SetStateAction<string>; }; }) {
    SetDocumentoResponsable(event.target.value)
  }
  function abrirData() {
    setModalData(!ModalData)

  }
  function formularioPpago(event: { preventDefault: () => void }) {
    event.preventDefault()
    abrirContrato()
  }
  const addCommas = (num: string) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  const removeNonNumeric = (num: { toString: () => string; }) => num.toString().replace(/[^0-9]/g, "");
  function thousandSeparator(valor: number) {
    return addCommas(removeNonNumeric(valor))
  }

  return (
    <>

      <section className='w-100'>
        <div className='pb-10 pb-lg-15'>
          <h2 className='fw-bolder text-dark'>Resumen de tu matrícula</h2>

          <div className='text-gray-400 fw-bold fs-6'>
            Esta es la información que nos has suministrado para tu proceso de matricula.
            <a href='https://web.whatsapp.com/send?phone=573113983972&text=' target="_blank" className='text-primary fw-bolder'>
              ¿Tienes dudas? Contáctanos
            </a>
          </div>
        </div>

        <div className='card'>
          <div className='card-body d-flex flex-center flex-column p-9'>
            <div className='fs-4 text-gray-800 text-hover-primary fw-bolder mb-0'>
              {Nombre} {Apellidos}
            </div>
            <div className='fw-bold text-gray-400 mb-6'>{initValues.nombreCurso}</div>
            <div className='d-flex flex-center flex-wrap mb-5'>
              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mx-3 mb-3'>
                <div className='fs-6 fw-bolder text-gray-700'>Modalidad del programa</div>
                <Select id='modalidad'
                  name='modalidad'
                  value={initValues.modalidades.find(loadModalidad)}
                  options={modalidad}
                  isDisabled={true}
                />
              </div>

              <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 mx-3 px-4 mb-3'>
                <div className='fs-6 fw-bolder text-gray-700'> {certificado && <p>COP {thousandSeparator(initValues.Vcertificado)}</p>} {!certificado && initValues.valorPagar <= 0 && <p>COP 0</p>} {!certificado && initValues.valorPagar > 0 && <p>COP {thousandSeparator(initValues.valorPagar - valorCupon)}</p>}</div>
                <div className='fw-bold text-gray-400'>Valor a pagar</div>
              </div>
              <label className="col-lg-8 col-form-label fw-bold fs-6">Cupon de descuento</label><br />
              <div className="row">
                <div className="col-9">
                  <input className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" name='cupon' id='cupon' type="text"></input>
                </div>
                <div className="col-3">

                  <button type="button" className="btn btn-sm btn-light-primary" onClick={validarCupon}><KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />Validar cupon</button>
                </div>
                <label className="col-lg-8 col-form-label fw-bold fs-6">Valor del cupon {thousandSeparator(valorCupon)}</label><br />
              </div>
              {initValues.TBPagoParcial && <div className='border border-gray-300 border-dashed rounded min-w-125px py-6 mx-6 px-8 mb-6'>
                <div id="pagoParcial">
                  <div onChange={pagoParcialChange}>
                    <label className="col-lg-8 col-form-label fw-bold fs-6">Deseas pagar parcialmente?</label><br />
                    <Field type="radio" value="true" className="form-check-input" name="esParcial" /> <label className="fw-bold fs-6">Si </label>
                    <Field type="radio" value="false" className="form-check-input" name="esParcial" /> <label className="fw-bold fs-6">No </label>
                  </div>
                  <div id='pagoParcialValor' style={{ display: 'none' }}>
                    <button type="button" className="btn btn-sm btn-light-primary" onClick={abrirContrato}><KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />Agregar valor a pagar</button>
                  </div>
                </div>
              </div>}
              {initValues.Tcertificado && <div className='border border-gray-300 border-dashed rounded min-w-125px py-6 mx-6 px-8 mb-6'>
                <div id="pagoParcial">
                  <div onChange={certificadoChange}>
                    <label className="col-lg-8 col-form-label fw-bold fs-6">Deseas pagar el certificado?</label><br />
                    <label className="col-lg-8 col-form-label fw-bold fs-6">Valor {initValues.Vcertificado} COP</label><br />
                    <Field type="radio" value="true" className="form-check-input" name="Bcertificado" /> <label className="fw-bold fs-6">Si </label>
                    <Field type="radio" value="false" className="form-check-input" name="Bcertificado" /> <label className="fw-bold fs-6">No </label>
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>

        <div className='fv-row'>
          <label className='d-flex align-items-center justify-content-between mb-6 cursor-pointer'>
            <span className='d-flex align-items-center me-2'>
              <span className='symbol symbol-50px me-6'>
                <span className='symbol-label bg-light-primary'>
                  <img
                    src={toAbsoluteUrl('/media/svg/card-logos/payu.png')}
                    alt=''
                    className='h-25px'
                  />
                </span>
              </span>

              <span className='d-flex flex-column'>
                <span className='fw-bolder fs-6'>Medio de pago: PayU Latam</span>
                <span className='fs-7 text-muted'>
                  Cuando le des en continuar te direccionaremos a PayU para completar tu proceso de pago.
                </span>
              </span>
            </span>

            <span className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='radio'
                value='payu'
                checked
                disabled
              />
            </span>
          </label>
        </div>

        <div className="row mb-12">
          <div className="col-lg-12 fv-row">
            <div className="d-flex align-items-center mt-6">
              <label className="form-check form-check-inline form-check-solid me-5">
                <Field className="form-check-input" type="checkbox" name="terminosYCondiciones"></Field>
                <div className="text-danger">
                  <ErrorMessage name='terminosYCondiciones' />
                </div>
                <p className="fw-bold ps-2 fs-6">Acepto los <a onClick={abrirterminos} className="text-primary" target="_blank" rel="noopener">términos y condiciones</a></p>
              </label>
              <label className="form-check form-check-inline form-check-solid">

                <Field className="form-check-input" type="checkbox" name="habeasData"></Field>
                <div className="text-danger">
                  <ErrorMessage name='habeasData' />
                </div>
                <p className="fw-bold ps-2 fs-6">Acepto las políticas de<a onClick={abrirData} className="text-primary" target="_blank" rel="noopener"> HABEAS DATA</a></p>
              </label>
            </div>
          </div>
        </div>
      </section>
      <Modal show={modalContrato} size="lg">
        <ModalHeader closeButton onHide={abrirContrato}>
          <h1>Contrato de Matrícula ASDI</h1>
        </ModalHeader>
        <ModalBody>

          <div className="example-container">
            <div className="row gx-3 gx-lg-3 row-cols-1 row-cols-md-2 row-cols-xl-3 justify-content-center">
              <div>
                <label className="col-lg-6 col-form-label fw-bold fs-8">Ingresa el valor que deseas pagar</label><br />
                <label className="col-lg-6 col-form-label fw-bold fs-8"> Valor Minimo  {thousandSeparator(initValues.valorMinParcial)}</label><br />
                <Field className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" onKeyUp={valorparcial} name='valorParcial' id='valorPagoExtemporaneo' type="number" />
                <div className="text-danger">
                  <ErrorMessage name='valorParcial' />
                </div>
              </div>
              {!initValues.mayor && <div id="esMenor" style={{ display: 'block' }}>
                <div id='esMenorValor' style={{ display: 'block' }}>
                  <label className="col-lg-6 col-form-label fw-bold fs-8">Nombre responsable</label><br />
                  <Field className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" name='nombrePparcial' onKeyUp={NResponsable} />

                  <label className="col-lg-6 col-form-label fw-bold fs-8">Número de cédula responsable</label><br />
                  <Field className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" name='cedulaPparcial' onKeyUp={DResponsable} />
                  <div className="text-danger">
                    <ErrorMessage name='cedulaPparcial' />
                  </div>
                </div>
              </div>}
            </div>
            <div style={{textAlign: "justify"}}>
              <p>
                Yo, {NombreResponsable} , con identificación {DocumentoResponsable}, en calidad de contratante, acepto la celebración del presente
                contrato de prestación de servicios educativos con JAIME HORACIO GÓMEZ ARCILA, identificado con cédula 70.901.974, en calidad de
                contratista y representante legal del Politécnico ASDI, institución educativa debidamente reconocida con licencia de funcionamiento
                No 0022133 del municipio de Rionegro - Antioquia, para llevar a cabo la formación en el programa {initValues.nombreCurso} en esta
                institución. El Politécnico ASDI deberá prestar el servicio de enseñanza entre el {initValues.FechaInicio} y el {initValues.FechaFin},
                poniendo a mi disposición la capacidad administrativa, la tecnología, el personal docente, y las demás condiciones que se requieran
                para llevar a cabo la formación, de acuerdo a las metodologías y las demás condiciones que corresponden a la oferta educativa
                seleccionada en la inscripción. Me comprometo a retribuir en su totalidad al Politécnico ASDI la suma de {thousandSeparator(initValues.valorPagar - valorCupon - valorP)}  por
                concepto de los servicios prestados y a cumplir el reglamento y manual de convivencia que esté vigente en el Politécnico ASDI.
              </p>
              <p>
                Autorizo a que, ante el incumplimiento de la obligación monetaria de este contrato, se me reporte en las centrales de riesgo que el
                Politécnico ASDI haya contratado, para el respectivo cobro jurídico sobre el valor adeudado a la fecha del reporte.
              </p>
              <p>
                La institución no realizará la devolución de dinero por ningún concepto, excepto en los casos que no se tenga el número mínimo de
                estudiantes matriculados al momento de iniciar la formación. El número mínimo de estudiantes matriculados dependerá de cada oferta y
                será fijado por el Politécnico ASDI.
              </p>

              <strong>
                <p>
                  Acepto los términos y condiciones del presente contrato, y el tratamiento de datos por parte del Politécnico ASDI. Certifico
                  que reconozco que al presionar la casilla de verificación ACEPTO este contrato, me estoy obligando sin tener que realizar ninguna firma manual.
                </p>
              </strong>

            </div>
            <div className="justify-content-center text-center mt-5">
              <button type="button" onClick={abrirContrato} className="btn btn-success w-100 py-3">Guardar</button>
            </div>
          </div>

        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      <Modal show={ModalTerminos} size="lg">
        <ModalHeader closeButton onHide={abrirterminos}>
          <h1>TERMINOS Y CONDICIONES DEL CONTRATO</h1>
        </ModalHeader>
        <ModalBody>
          <>
            <div className="text-center">
              <p className='text-justify'>En calidad de contratante, acepto la celebración del presente contrato de prestación de servicios educativos con JAIME HORACIO GÓMEZ ARCILA, identificado con cédula 70.901.974, en calidad de contratista y representante legal del Politécnico ASDI, institución educativa debidamente reconocida con licencia de funcionamiento No 0022133 del municipio de Rionegro - Antioquía, para llevar a cabo la formación.
                El Politécnico ASDI deberá prestar el servicio de enseñanza, poniendo a mi disposición la capacidad administrativa, la tecnología, el personal docente, y las demás condiciones que se requieran para llevar a cabo la formación, de acuerdo a las metodologías y las demás condiciones que corresponden a la oferta educativa seleccionada en la inscripción.
                Me comprometo a retribuir en su totalidad al Politécnico ASDI el valor de la matricula según el listado de precios anteriormente descripto, por concepto de los servicios prestados y a cumplir el reglamento y manual de convivencia que esté vigente en el Politécnico ASDI.
                La institución no realizará la devolución de dinero por ningún concepto, excepto en los casos que no se tenga el número mínimo de estudiantes matriculados al momento de iniciar la formación. El número mínimo de estudiantes matriculados dependerá de cada oferta y será fijado por el Politécnico ASDI.
                Acepto los términos y condiciones del presente contrato, y el tratamiento de datos por parte del Politécnico ASDI. Certifico que reconozco qué al presionar la casilla de verificación ACEPTO en este contrato, me estoy obligando sin tener que realizar ninguna firma manual.
              </p>

              <div className="justify-content-center text-center mt-5">
                <button type="button" onClick={abrirterminos} className="btn btn-success w-100 py-3">Listo</button>
              </div>
            </div>
          </>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
      <Modal show={ModalData} size="lg">
        <ModalHeader closeButton onHide={abrirData}>
          <h1>POLÍTICA DE TRATAMIENTO Y PROTECCIÓN DE DATOS PERSONALES
            POLITÉCNICO ASDI
          </h1>
        </ModalHeader>
        <ModalBody>
          <>
            <div className="text-center">

              <p className='text-justify'>El Politécnico ASDI, dando cumplimiento a lo establecido en la ley 1581 de 2012 y al decreto 1377 de 2013, ha decidido adoptar la política para para el tratamiento de datos personales los cuales son recolectados por la institución para el desarrollo de sus funciones, se busca garantizar el derecho de los titulares de conocer, actualizar, modificar o eliminar cualquier tipo de información o dato que hayan suministrado a la institución.
                De acuerdo a nuestras políticas de tratamiento de datos personales, los mecanismos a través de los cuales hacemos uso de éstos son seguros y con¬fidenciales, contamos con los medios tecnológicos apropiados para asegurarlos e impedir el uso no adecuado por parte de terceras personas y garantizar su debido resguardo y confidencialidad, direccionando la información a los fines académicos.

                •	Responsable del tratamiento de la información
                Nombre y razón social de la institución: Politécnico ASDI, es una institución para el trabajo y el desarrollo humano ubicada en el municipio de Rionegro con licencia de funcionamiento otorgada mediante la resolución 0022133 por la secretaria de educación departamental de Antioquia, certificada por la norma ISO 9001:2008, la NTC 5581 en la acreditación de sus programas.
                Contacto: protecciondedatos@asdi.edu.co
                Dirección: Calle 48 Nro. 51-59 Rionegro, Antioquia
                Teléfono: (604) 5616000

                •	Cómo se utiliza la información recolectada.
                La información suministrada y documentos cargados al sistema están protegidos para que no exista un copie de los datos, Politécnico ASDI solo utilizara sus datos única y exclusivamente con el objeto de cumplir con los requisitos del proceso educativo.

                •	Tratamiento de los datos personales
                Politécnico ASDI recolectará, utilizará y almacenará los datos personales de estudiantes, docentes, administrativos, egresados, proveedores, ex empleados o de cualquier persona que haya formado parte directa o indirectamente de la institución, regido al marco legal vigente y teniendo en cuenta los fines académicos y misionales de la institución, se contará con el consentimiento previo, expreso e informado del titular. Los datos personales podrán ser recolectados y tratados para:
                •	Cumplir con los requisitos mínimos de ingreso a los programas técnicos, cursos de extensión o diplomados virtuales que ofrece la institución
                •	Realizar envió de información relacionada con programas, cursos, diplomados, eventos y demás actividades y servicios ofrecidos por la institución
                •	Mantener un constante contacto con todas las personas pertenecientes a la comunidad educativa
                •	Realizar encuestas relacionadas con los servicios ofrecidos por la institución
                •	Propiciar el seguimiento a los compromisos establecidos entre el titular de los datos personales y la institución.

                DERECHOS QUE LE ASISTEN COMO TITULAR:
                Conforme a lo estipulado en la normatividad vigente en materia de protección de datos, se establecen los derechos de los titulares de los datos personales:
                •	Usted o su representante legal puede conocer, actualizar y rectificar los datos personales
                •	Solicitar la prueba de su autorización para el tratamiento de sus datos personales
                •	Revocar la autorización y/o solicitar la supresión de sus datos personales de las bases de datos
                •	Ser informado del uso que se les dé a los datos personales

                Derechos de los niños y adolescentes
                En el tratamiento de datos personales se asegurará el respeto a los derechos prevalentes de los menores.
                Es tarea del Estado y las entidades educativas de todo tipo proveer información y capacitar a los representantes legales y tutores sobre los eventuales riesgos a los que se enfrentan los niños, niñas y adolescentes respecto del Tratamiento indebido de sus datos personales, y proveer de conocimiento acerca del uso responsable y seguro por parte de niños, niñas y adolescentes de sus datos personales, su derecho a la privacidad y protección de su información personal y la de los demás.

                MECANISMOS PARA CONOCER LA POLÍTICA DE TRATAMIENTO Y SUS CAMBIOS SUSTANCIALES
                La política de tratamiento de datos personales está a disposición de toda la comunidad educativa en general, puede ser consultada en nuestra página web www.asdi.edu.co – Política de tratamiento de datos personales. Cualquier modificación a dicho documento o al presente aviso, será informado al momento de implementar tales modificaciones por los mismos medios por vía de los cuales se mantiene a su disposición esta información.
              </p>

              <div className="justify-content-center text-center mt-5">
                <button type="button" onClick={abrirData} className="btn btn-success w-100 py-3">Listo</button>
              </div>
            </div>
          </>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </>

  )
}

export { Step4 }
