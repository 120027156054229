import React, { FC, useEffect, useState } from 'react'
import { KTSVG } from '../../../../../_metronic/helpers'
import { Field, ErrorMessage } from 'formik'
import { useLocation } from 'react-router-dom'
import { environment } from '../../../../../environments/environment'
import Select from 'react-select'
import { createAccountSchemas, ICreateAccount, inits } from '../CreateAccountWizardHelper'

const Step2: FC = () => {
    const [initValues] = useState<ICreateAccount>(inits)
    const baseurl = environment.acamoodURL
    const [municipio, setMunicipio] = useState([])
    const [sector, setSector] = useState([])
    const [trabajando, settrabajando] = useState(false)
    const [educativelevel, setEducativelevel] = useState([])
    const [institucion, setInstitution] = useState([])
    const [Mayor, SetMayor] = useState(false)
    let location = useLocation();
    const path = location.pathname.split('/')[location.pathname.split("/").length - 1]
    const url = baseurl + environment.EducativeOffertURL + environment.OffertByCod + path
    useEffect(() => {
        const Muninicipios = async () => {
            const url = baseurl + environment.CommonUrl + environment.Municipalities
            const respuesta = await fetch(url)
            const resultado = await respuesta.json()
            const Lista = resultado.data.map(
                (Municipio: any) => {
                    const objeto = {
                        value: +Municipio.id_mun,
                        label: Municipio.nom_mun
                    }
                    return objeto
                })
            setMunicipio(Lista)
        }
        Muninicipios()
        const institutions = async () => {
            const url = baseurl + environment.CommonUrl + environment.institution
            const respuesta = await fetch(url)
            const resultado = await respuesta.json()
            const Lista = resultado.data.map(
                (institution: any) => {
                    const objeto = {
                        value: +institution.NInstitucionId,
                        label: institution.SNombre
                    }
                    return objeto
                })
            setInstitution(Lista)
        }
        institutions()
        const educativelevel = async () => {
            const url = baseurl + environment.CommonUrl + environment.educativelevel
            const respuesta = await fetch(url)
            const resultado = await respuesta.json()
            const Lista = resultado.data.map(
                (Level: any) => {
                    const objeto = {
                        value: +Level.id_niv_edu,
                        label: Level.nom_niv_edu
                    }
                    return objeto
                })
            setEducativelevel(Lista)
        }
        educativelevel()
        const Sector = async () => {
            const url = baseurl + environment.CommonUrl + environment.sectors
            const respuesta = await fetch(url)
            const resultado = await respuesta.json()
            const Lista = resultado.data.map(
                (Sector: any) => {
                    const objeto = {
                        value: +Sector.NSectorEconomicoId,
                        label: Sector.SNombre
                    }
                    return objeto
                })
            setSector(Lista)
        }
        Sector()
    }, [])
    const onChabgeName = (event: any) => {
        initValues.Nombre = event.target.value
    }
    const onChabgeDocument = (event: any) => {
        initValues.NIdentificacion = event.target.value
    }
    const onChabgeLastName = (event: any) => {
        initValues.Apellidos = event.target.value
    }
    const onChangeEdad = (event: any) => {

        var birthday = event.target.value
        var hoy = new Date();
        var cumpleanos = new Date(birthday!);
        var edad = hoy.getFullYear() - cumpleanos.getFullYear();
        var m = hoy.getMonth() - cumpleanos.getMonth();

        if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
            edad--;
        }
        if (edad > 18) {
            SetMayor(true)
        } else {
            SetMayor(false)

        }
    }
    initValues.mayor = Mayor
    const trabajoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        settrabajando(event.target.value === 'true')
    };
    return (
        <section className="w-100">
            <div className='pb-10 pb-lg-15'>
                <h2 className='fw-bolder d-flex align-items-center text-dark'>
                    Información Personal
                </h2>
                <div className='text-gray-400 fw-bold fs-6'>
                    Ingrese los siguientes datos en el formulario, los campos marcados con asterisco (*) son obligatorios.
                </div>
            </div>
            <div className='fv-row mb-10'>
                <label className='form-label required'>N° de identificación:</label>
                <Field className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" onKeyUp={onChabgeDocument} type="text" name="NIdentificacion"></Field>
                <div className="text-danger">
                    <ErrorMessage name='NIdentificacion' />
                </div>
            </div>

            <div className='fv-row'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <label htmlFor="nombre" className='form-label required'>Nombres:</label>
                        <Field className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" type="text" onKeyUp={onChabgeName} name="Nombre"></Field>
                        <div className="text-danger">
                            <ErrorMessage name='Nombre' />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <label htmlFor="Apellidos" className='form-label required'>Apellidos:</label>
                        <Field className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" onKeyUp={onChabgeLastName} type="text" name="Apellidos"></Field>
                        <div className="text-danger">
                            <ErrorMessage name='Apellidos' />
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className='col-lg-6'>
                        <label htmlFor="Email" className='form-label required'>Correo electrónico:</label>
                        <Field className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" type="mail" name="Email"></Field>
                        <div className="text-danger">
                            <ErrorMessage name='Email' />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <label htmlFor="Email" className='form-label required'>confirmar Correo electrónico:</label>
                        <Field className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" type="text" name="emailConfirmation" onPaste={(e: { preventDefault: () => void }) => {
                            e.preventDefault()
                            return false;
                        }} onCopy={(e: { preventDefault: () => void }) => {
                            e.preventDefault()
                            return false;
                        }}></Field>
                        <div className="text-danger">
                            <ErrorMessage name='emailConfirmation' />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <label htmlFor="FNaciemiento" className='form-label required'>Fecha de nacimiento:</label>
                        <Field className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" type="date" onBlur={onChangeEdad} name="FNaciemiento"></Field>
                        <div className="text-danger">
                            <ErrorMessage name='FNaciemiento' />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <label htmlFor="Telefono" className='form-label required'>Teléfono de Contacto:</label>
                        <Field className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" type="number" name="Telefono"></Field>
                        <div className="text-danger">
                            <ErrorMessage name='Telefono' />
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-lg-6'>
                        <label htmlFor="Municipio" className='form-label required'>Municipio/Ciudad:</label>
                        <Field as="select" className="form-select form-select-lg form-select-solid" name='Municipio'>
                            <option></option>
                            {municipio.map((objeto: any) => {
                                return (<option key={objeto.value} value={objeto.value}>{objeto.label}</option>)
                            })}
                        </Field>
                        <div className="text-danger">
                            <ErrorMessage name='Municipio' />
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <label htmlFor="NAcademico" className='form-label required'>Nivel academico:</label>

                        <Field as="select" className="form-select form-select-lg form-select-solid" name='NivelAcademico'>
                            <option></option>
                            {educativelevel.map((objeto: any) => {
                                return (<option key={objeto.value} value={objeto.value}>{objeto.label}</option>)
                            })}
                        </Field>
                        <div className="text-danger">
                            <ErrorMessage name='NivelAcademico' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6'>
                    <label htmlFor="sexo" className='form-label required'>Sexo:</label>
                    <Field as="select" className="form-select form-select-lg form-select-solid" name='Sexo'>
                        <option></option>
                        <option value="1">Masculino</option>
                        <option value="2">Femenino</option>
                        <option value="3">Prefiere no decirlo</option>
                    </Field>
                    <div className="text-danger">
                        <ErrorMessage name='Sexo' />
                    </div>
                </div>
                <div className='col-lg-6'>
                    <label htmlFor="MConocioI" className='form-label'>Medio por el que conoció la institución:</label>
                    <Field as="select" className="form-select form-select-lg form-select-solid" name='medio'>
                        <option></option>
                        <option value="Redes Sociales">Redes Sociales</option>
                        <option value="Página Web">Página Web</option>
                        <option value="Publicidad radial">Publicidad radial</option>
                        <option value="Publicidad física">Publicidad física</option>
                        <option value="Referidos">Referidos</option>
                        <option value="Otros">Otros</option>
                    </Field>

                </div>
            </div>
            <div className='row'>
                {initValues.tipo && <div className='col-lg-6'>
                    <label htmlFor="NAcademico" className='form-label required'>Institucion:</label>
                    <Field as="select" className="form-select form-select-lg form-select-solid" name='institucionEducativaId'>
                        <option></option>
                        {institucion.map((objeto: any) => {
                            return (<option key={objeto.value} value={objeto.value}>{objeto.label}</option>)
                        })}
                    </Field>
                </div>}
                {initValues.tipo && <div className='col-lg-6'>
                    <label htmlFor="NAcademico" className='form-label required'>Año de graduacion:</label>
                    <Field className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" name="anioGraduacion" type="number"></Field>
                </div>}
            </div>
            {initValues.tipo && <div className='row'>
                        <div className='col-lg-6'>
                        <label htmlFor="BTrabaja" className='form-label required'>Actualmente está trabajando?:</label><br />
                                <Field as="select" className="form-select form-select-lg form-select-solid" name='trabaja'>
                                    <option value="true">Si</option>
                                    <option value="false">No</option>
                                </Field>
                        </div>
                        {trabajando && <div className='col-lg-6'>
                        <label htmlFor="BTrabaja" className='form-label required'>Sector:</label>
                                <Field as="select" className="form-select form-select-lg form-select-solid" name='sectorEconomicoId'>
                                    <option></option>
                                    {sector.map((objeto: any) => {
                                        return (<option key={objeto.value} value={objeto.value}>{objeto.label}</option>)
                                    })}
                                </Field>
                            </div>}
                    </div>}
        </section>
    )
}

export { Step2 }
