export const environment = {
    production: false,
    //http://localhost/Acamod_api_acasoftw/public/api/
    //https://acamood.dpsoluciones.co/api/public/api/
    //https://acamood.dpsoluciones.co/acamood/public/api/
    //URL
    apiURL :'https://acamood.dpsoluciones.co/Acamood/public/api/',
    acamoodURL:'https://acamood.dpsoluciones.co/Acamood/public/api/',
    //oferta educativa
    EducativeOffertURL:"educativeoffer/",
    Programs:"programs",
    Modalities:"modalities",
    SaveOffert:"saveoffert",
    UpdateOffert:"updateoffert/",
    GroupsByCod:"groupsbycod/",
    OffertByCod:"offertbycod/",
    PublicGroups:"publishedgroups",
    programTypes:"programTypes",
    educativelevel :"educativeLevel",
    institution:"institution",
    //moodle
    MoodleUrl:"moodle/",
    MoodleCourses:"courses",
    //common
    CommonUrl:"common/",
    Municipalities:"municipalities",
    sectors:"sectors",
    cupon:"validateCupon",
    ExistUserCourse:"existUserGroup",
    //enrollment
    enrollmentUrl:"enrollment/",
    enrollment: "saveenrollment",
    //reportes
    reportUrl: "report/",
    registered: "registered",
    report:"report",
    //Etiquetas form princial
    FCodigo:"Codigo",
    FNombre:"Nombre",
    FPrograma:"Programa",
    FModalidad:"Modalidad",
    FHorario:"Horario",
    FMensajeErrorHorario:"agrege un horario",
    FCupo:"Cupo",
    FFechaInicioCurso:"Inicio del curso",
    FFechaFinCurso:"Finalización del curso",
    FDescripcion:"Descripcion",
    FTieneDescuento:"¿El curso tiene descuento?",
    FAgregarDescuentoBoton:"Agregar Descuento",
    FMensajeErrorDescuento:"Seleciona un valor",
    FCertificado:"¿Paga el certificado?",
    FICretificado:"Ingrese el valor del certificado",
    FValor:"¿El curso tiene valor?",
    FValorBoton:"Agregar valor",
    FMensajeErrorValor:"agrege un valor",
    FFechaMatricula:"Fechas de matricula",
    FFechaMatriculaInicio:"Fecha de inicio",
    FFechaMatriculaFin:"Fecha de finalizacion",
    FEstaEnMooodle:"¿El curso esta en moodle?",
    FMensajeErrorMoodle:"Seleciona un valor",
    FBotonFromularioP:"Guardar",
    //Formulario de valores
    Ftitulo:"Asignación de valores",
    FtituloProntopago:"Pronto pago",
    FtituloPagoOdinario:"Pago ordinario",
    FtituloPagoExtempo:"Pago extemporáneo",
    Finicio:"Inicio",
    Ffin:"Fin",
    FvalorFv:"Valor",
    FpagoParcial:"¿Se podrá pagar parcialmente?",
    FValorMinimo:"Valor minimo",
    FBotonGuardar:"Guardar",
    //Formulario Cupones --- descuentos
    FTituloCupones:"Cupones especiales",
    Festado:"Activo",
    FSValor:"Seleciona un valor",
    FCodigoCupon:"Código del cupon",
    FValorDescuento:"Valor del descuento",
    FCantidadUsos:"Cantidad de usos",
    FBotonCupones:"Crear",
    Testado:"Activo",
    Tcodigo:"Codigo",
    Tvalor:"Valor",
    Tusos:"Usos",
    Teliminar:"Eliminar",
    //Formulario Horarios
    FtituloHorario:"Asignar Horario",
    FMensajeErrorDia:"Seleciona un valor",
    FHoraInicio:"Hora de inicio",
    FHoraFin:"Hora de finalización",
    FbotonHorario:"guardar",
    TDIa:"Dia",
    THoraInicio:"Hora de inicio",
    THoraFin:"Hora de finalización",
    TeliminarHorario:"Eliminar",
  };